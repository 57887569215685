<script lang="ts">
  import { generateNewPassword } from './lib/password.helper';
  import { onMount, text, tick } from 'svelte/internal';
  import { fade } from 'svelte/transition';
  import zxcvbn, { ZXCVBNResult } from 'zxcvbn';

  let password: string = '';
  let specialCharacters: boolean = true;

  let message: string = '';

  let inputField: HTMLInputElement;
  async function copy() {
    await tick();
    inputField.focus();
    inputField.select();
    message = 'Copied!';
    try {
      const successful = document.execCommand('copy');
      if (!successful) {
        message = 'Copying text was unsuccessful';
      }
    } catch (err) {
      message = 'Oops, unable to copy';
    }

    setTimeout(() => {
      message = '';
    }, 500);
  }

  function generate() {
    password = generateNewPassword(!specialCharacters);
  }

  onMount(() => {
    generate();
  });
</script>

<main class="bg-gray-900 min-w-screen min-h-screen text-gray-200 flex text-center">
  <div class="content m-auto">
    <div class="text-6xl text-green-500 m-6"><i class="fad fa-key" /></div>
    <div class="text-xl text-gray-600 m-5 h-5 font-bold">
      {#if message !== ''}
        <p in:fade={{ duration: 100 }} out:fade={{ duration: 500 }}>{message}</p>
      {/if}
    </div>
    <input
      on:click={copy}
      type="text"
      class="cursor-pointer bg-gray-300 hover:bg-gray-400 text-xl text-gray-800 font-bold py-2 px-4
        text-center rounded inline-flex items-center"
      bind:value={password}
      bind:this={inputField}
      autocomplete="off"
      autocorrect="off"
      autocapitalize="off"
      spellcheck="false" />
    <br />
    <label
      class="text-gray-600 hover:text-gray-400 font-semibold text-center rounded inline-flex
        items-center cursor-pointe my-5 h-5">
      <input bind:checked={specialCharacters} on:change={generate} type="checkbox" />
      <span class="text-l m-2"> Special characters? </span>
    </label>
    <div
      class="text-l hover:text-gray-400 text-center rounded inline-flex items-center cursor-pointer
        text-gray-600 my-5 h-5 font-semibold"
      on:click={generate}>
      <div class="text-l m-5"><i class="fad fa-sync" /></div>
      Generate new
    </div>
  </div>
</main>
