import zxcvbn from 'zxcvbn';
/**
 * Return a newly generated password
 * @param noSpecialCharacters True, when the password should not contain any special characters
 */
export const generateNewPassword = (noSpecialCharacters = false) => {
    const lettersAndNumbers = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    const specialCharacters = '!"#$%&\'()*+,-./:;<=>?@[]^_`{|}~\\';
    const charset = noSpecialCharacters ? lettersAndNumbers : lettersAndNumbers + specialCharacters;
    let password = '';
    for (var i = 0, n = charset.length; i < 10; ++i) {
        password += charset.charAt(Math.floor(Math.random() * n));
    }
    if (!isStrong(password))
        return generateNewPassword(noSpecialCharacters);
    return password;
};
/**
 * Tests if a password is strong enough
 * @param password The password to test
 */
export const isStrong = (password) => {
    return zxcvbn(password).score >= 2;
};
